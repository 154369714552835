import * as React from 'react'

import Layout from '../components/layout'

import * as Styles from '../styles/layout.module.scss'

function Datenschutz({ pageContext }) {

    var translationContext = {
        locale: 'de',
        defaultMenu: false
    };
    return (
        <Layout title="Datenschutz"
            pageContext={pageContext}
            translationContext={translationContext}>
            <div class={Styles.centeredLayout}>
                <h1>Datenschutz</h1>
                <h2>Allgemeine Hinweise</h2>
                <p>
                    Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser Datenschutzerklärung.</p>
                <p>
                    Wenn Sie diese Website benutzen, werden verschiedene personenbezogene Daten erhoben. Personenbezogene Daten sind Daten, mit denen Sie persönlich identifiziert werden können. Die vorliegende Datenschutzerklärung erläutert, welche Daten wir erheben und wofür wir sie nutzen. Sie erläutert auch, wie und zu welchem Zweck das geschieht.
                </p>
                <p>
                    Wir weisen darauf hin, dass die Datenübertragung im Internet (z. B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich.
                </p>
                <p>
                    Hinweis zur verantwortlichen Stelle
                    Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist:
                    <br />
                    <p style={ { margin:   `20px  `} }>
                    <b>Brandeis Consulting GmbH</b> <br />
                    Rheindammstr. 5 <br />
                    68163 Mannheim <br />
                    Geschäftsführer Jörg Brandeis <br />
                    Amtsgericht Mannheim HRB 735382 <br />

                    Telefon: +49 (0) 621 48494961 <br />
                    E-Mail: info@brandeis.de<br />
                    </p>
                </p><p>
                    Auf unserer Website sind unter anderem Tools von Unternehmen mit Sitz in den USA oder sonstigen datenschutzrechtlich nicht sicheren Drittstaaten eingebunden. Wenn diese Tools aktiv sind, können Ihre personenbezogene Daten in diese Drittstaaten übertragen und dort verarbeitet werden. Wir weisen darauf hin, dass in diesen Ländern kein mit der EU vergleichbares Datenschutzniveau garantiert werden kann. Beispielsweise sind US-Unternehmen dazu verpflichtet, personenbezogene Daten an Sicherheitsbehörden herauszugeben, ohne dass Sie als Betroffener hiergegen gerichtlich vorgehen könnten. Es kann daher nicht ausgeschlossen werden, dass US-Behörden (z.B. Geheimdienste) Ihre auf US-Servern befindlichen Daten zu Überwachungszwecken verarbeiten, auswerten und dauerhaft speichern. Wir haben auf diese Verarbeitungstätigkeiten keinen Einfluss.
                </p><h3>Widerruf Ihrer Einwilligung zur Datenverarbeitung</h3>
                <p>
                    Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen Einwilligung möglich. Sie können eine bereits erteilte Einwilligung jederzeit widerrufen. Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.

                </p>
                Verantwortlich im Sinne des Presserechts: Jörg Brandeis
                <h2>Hosting der Website</h2>

               <p>Diese Website ist bei der <b><a href="https://www.netlify.com/">Netlify Inc. </a></b>( 44 Montgomery Street, Suite 300, San Francisco, CA 94104 ) gehostet. Ihre IP-Addresse wird von Netlify aus technischen Gründen gespeichet. Diese wird aber nach maximal 30 Tage wieder gelöscht. Die folgenden Dokumente zeigen weitere Details:
                <ul>
                        <li><a href="https://www.netlify.com/gdpr-ccpa/">Netlify GDPR (Englisch) (Englisch)</a></li>
                        <li><a href="https://www.netlify.com/privacy/">Netlify Datenschutzerklärung(Englisch) (Englisch)</a>
                        </li>
                    </ul>
                    </p>
                <h2>Formulare</h2>
                <p>Wir speichern lediglich die Daten von Ihnen, die Sie explizit in Formularfelder eingeben. Die Speicherung erfolgt auf den Servern der Firma <b><a href="https://airtable.com/">Airtable</a></b> (155 5th St, San Francisco, California 94103) .

                    <ul>
                        <li><a href="https://www.airtable.com/privacy">Airtable Datenschutzerklärung (Englisch)</a>
                        </li>
                    </ul>
                </p>

                <h2>Anmeldung im Trainingsportal</h2>
                <p>
                Für die Benutzerverwaltung verwenden den Service <b><a href="https://firebase.google.com/docs/auth?hl=de">Firebase Authentification</a></b> der Firma Google. Dieser speichert die Daten ab, die Sie bei der Registrierung auf der Seite training.brandeis.de eingeben. Das Kennwort wird verschlüsselt abgespeichert und kann nicht ausgelesen werden. Kein Mitarbeiter der Brandeis Consulting GmbH kennt Ihr Kennwort und wird Sie jemals danach fragen. 

                <ul>
                        <li><a href="https://firebase.google.com/support/privacy?hl=de">Datenschutz und Sicherheit in Firebase</a>
                        </li>
                    </ul>
                
                </p>
                <h2>Cookies</h2>
                <p>Wir verwenden ausschliesslich Cookies von unserer eigenen Domäne <b>brandeis.de</b> bzw. <b>training.brandeis.de</b>.</p>
  
            </div>
        </Layout>
    )
}

export default Datenschutz